<template>
  <table class="table table-bordered">
    <thead class="thead-dark">
      <tr>
        <th class="text-center">#</th>
        <th>Name</th>
        <th class="text-center">Bearbeiten</th>
        <th class="text-center">Löschen</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="7">
          <router-link :to="{ name: 'holding-create' }"
            >+ Holding erstellen</router-link
          >
        </td>
      </tr>
      <tr v-for="(holding, index) in holdings" :key="holding.id">
        <th class="text-center">{{ index + 1 }}</th>
        <td>{{ holding.name }}</td>
        <td class="text-center">
          <router-link
            :to="{ name: 'holding-details', params: { id: holding.id } }"
          >
            <i class="fa fa-edit text-dark"></i>
          </router-link>
        </td>
        <td class="text-center" @click="remove(holding.id)">
          <i class="fa fa-trash cursor-pointer"></i>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { firestore } from '../firebase';

export default {
  data() {
    return {
      holdings: [],
      loading: false
    };
  },
  async created() {
    this.loading = true;

    const holdingsSnapshot = await firestore
      .collection('holdings')
      .orderBy('name')
      .get();

    this.holdings = holdingsSnapshot.docs.map(snapshot => {
      return {
        id: snapshot.id,
        ...snapshot.data()
      };
    });

    this.loading = false;
  },
  methods: {
    async remove(id) {
      if (confirm('Bist du sicher, dass du diese Holding löschen möchtest?')) {
        await firestore
          .collection('holdings')
          .doc(id)
          .delete();

        this.holdings = this.holdings.filter(holding => holding.id !== id);
      }
    }
  }
};
</script>
